import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path2/src/components/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Recipe = makeShortcode("Recipe");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Obtaining`}</h2>
    <h3>{`Crafting`}</h3>
    <Recipe item="wire" inputs="copper" mdxType="Recipe" />
    <h2>{`Usage`}</h2>
    <h3>{`Crafting`}</h3>
    <Recipe item="wire" outputs="circuit,processor,lcd_screen,batteries" mdxType="Recipe" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      